// @ts-nocheck
import Card from 'atoms/card/card';
import Divider from 'atoms/divider/divider';
import Heading from 'atoms/heading/heading';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import SideCar from 'molecules/sidecar/sidecar';
import Snackbar from 'molecules/snackbar/snackbar';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import CustomizeViewModal from 'organisms/CustomizeViewModal';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import UploadData from 'organisms/uploadData';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import * as constants from './constants';
import Breadcrumbs from 'atoms/breadcrumbs/breadcrumbs';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import ExportGrid from 'organisms/exportGrid';
import ToggleAgGrid from 'organisms/toggleAgGrid';
import { REQUESTS } from 'utils/requests';
import GridActionBar from 'atoms/GridActionBar';
import GridCsvUpload from 'organisms/uploadData/gridCsvUpload';
import { isAclUsersReadWriteEnabled, isNewUploadEnabled } from 'models/user/selector';
import Button from 'atoms/button/button';
import { CreateUser } from './components/createUser';
import { triggerActivateUser, triggerDeactivateUser } from './sagaActions';
import { triggerFetchAutoComplete } from 'models/autoComplete/sagaActions';
import { Space, Tag } from 'antd';
import { getAutoCompleteData } from 'models/autoComplete/selectors';

const ITEMS_MODAL = 'items-modal';

const getColumns = ({ onEditRow, onRemoveUserRole, onDeactivateUser, onActivateUser }) => ([
    {
        title: "User Name",
        // dataIndex: 'name',
        key: "UserName",
        displayName: 'User Name',
        render: (obj) => obj.UserName,
    },
    {
        title: "User Email",
        // dataIndex: 'name',
        key: "UserEmail",
        displayName: 'User Name',
        render: (obj) => obj.UserEmail,
    },
    {
        title: "Roles",
        // dataIndex: 'name',
        key: "Roles",
        displayName: 'Roles',
        width: '30%',
        render: (obj) => <Stack isWrap gutter="medium">{_.map(obj.Roles, r => <StackItem><Tag closable onClose={() => onRemoveUserRole(obj, r)}>{r.RoleName}</Tag></StackItem>)}</Stack>,
    },
    {
        title: "Status",
        // dataIndex: 'name',
        key: "Status",
        displayName: 'Status',
        width: '10%',
        render: (obj) => <Stack isWrap gutter="medium">{obj.IsActive ? 'Active' : 'Inactive'}</Stack>,
    },
    {
        title: "Action",
        key: "action",
        render: (obj) => (
            <Space size="middle">
                {obj.IsActive && <a onClick={() => onEditRow(obj)}>Edit</a>}

                {obj.IsActive ? <a onClick={() => onDeactivateUser(obj)}>Deactivate</a>
                    : <a onClick={() => onActivateUser(obj)}>Activate</a>}
            </Space>
        ),
    },
]);
let debounce;

const Users: React.FunctionComponent = props => {
    const [openFilter, setOpenFilter] = React.useState<boolean>();
    const [isUploadEnabled, toggleUpload] = React.useState<boolean>(false);
    const [isDeleteModal, toggleDeleteModal] = React.useState(false);
    const gridMeta = useSelector(state => dynamicGridSelectors.getGridMetadata(state, constants.TABLE_ID));
    const [createUserInfo, setCreateUser] = React.useState(null);
    const usersList = useSelector(state => getAutoCompleteData(state, 'acl-all-users')?.filter(u => u.UserEmail !== sessionStorage.getItem('userEmail')))


    const tableId = constants.TABLE_ID.toString();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hasWriteAccess = useSelector(isAclUsersReadWriteEnabled);
    const hasDeleteAccess = useSelector;
    const hasSearchFilters = useSelector(state => dynamicGridSelectors.hasSearchFilters(state, constants.TABLE_ID));

    const isCustomizeViewModalEnabled = useSelector(state => tableColumnSelectors.isCustomizeViewModalEnabled(state));
    // React.useEffect(() => {
    //   dispatch(tableColumnSagaActions.fetchTableColumns(constants.TABLE_ID));
    // }, [dispatch, tableId]);

    const fetchAllUsers = (search) => {
        dispatch(
            triggerFetchAutoComplete({
                componentId: 'acl-all-users',
                options: {
                    service: 'acl',
                    url: 'users/list',
                    displayKey: 'UserName',
                    selectionKey: 'UserID',
                },
                params: { search, fieldGroups: ['Roles', 'UserEmail'] }
            }));
    }
    useEffect(() => {
        dispatch(
            triggerFetchAutoComplete({
                componentId: 'acl-roles',
                options: {
                    service: 'acl',
                    url: 'roles/list',
                    body: {
                        IsActive: true
                    },
                    displayKey: 'RoleName',
                    selectionKey: 'RoleID'
                }

            }))
        fetchAllUsers();
    }, [])
    
    React.useEffect(() => {
        if (openFilter === undefined) {
            setOpenFilter(hasSearchFilters);
        }
    }, [hasSearchFilters]);

    const handleFilterClick = React.useCallback(() => {
        setOpenFilter(!openFilter);
    }, [openFilter]);

    const handleRefreshClick = React.useCallback(() => {
        dispatch(
            dynamicGridSagaActions.refreshGridData({
                tableId: constants.TABLE_ID,
            })
        );
    }, [tableId, dispatch]);

    const handleBackClick = React.useCallback(() => {
        navigate(`/masterData`);
    }, [navigate]);

    const handleResetTableColumns = React.useCallback(async () => {
        dispatch(tableColumnSagaActions.resetTableColumnsAg(constants.TABLE_ID));
    }, [dispatch, tableId]);

    const customizeViewHandler = React.useCallback((isCustomizeViewModalEnabled: boolean) => {
        dispatch(tableColumnSagaActions.setCustomizeViewModal(isCustomizeViewModalEnabled));
    }, []);

    const onRemoveUserRole = (user, role) => {
        dispatch(triggerRemoveUserRole({ userId: user?.UserID, roles: [role?.RoleID] }))
    }
    const onDeactivateUser = (user) => {
        dispatch(triggerDeactivateUser({ userId: user?.UserID }))
        setTimeout(()=>{
            dispatch(
                dynamicGridSagaActions.refreshGridData({
                    tableId: constants.TABLE_ID,
                })
            );
        },2000)

    }

    const onActivateUser = (user) => {
        dispatch(triggerActivateUser({ userId: user?.UserID }))
        setTimeout(()=>{
            dispatch(
                dynamicGridSagaActions.refreshGridData({
                    tableId: constants.TABLE_ID,
                })
            );
        },2000)
        
    }

    const onEditRow = (row) => {
        setCreateUser(row)
    }

    const handleMenuItemClick = React.useCallback(
        (option: string) => {
            switch (option) {
                case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
                    return customizeViewHandler(true);
                case constants.MENU_ITEMS_VALUES.RESET_VIEW:
                    return handleResetTableColumns();
                case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
                    return toggleUpload(true);
                case constants.MENU_ITEMS_VALUES.DELETE:
                    return deleteItems();
                default:
                    return;
            }
        },
        [handleResetTableColumns]
    );

    const selectedItems = useSelector(state => dynamicGridSelectors.getTableSelection(state, constants.TABLE_ID));
    const getDisabledStatus = React.useCallback(
        (option: any) => {
            switch (option.value) {
                case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
                case constants.MENU_ITEMS_VALUES.RESET_VIEW:
                case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
                    return false;
                case constants.MENU_ITEMS_VALUES.DELETE:
                    return !selectedItems?.selectedRowKeys?.length;
                default:
                    return true;
            }
        },
        [selectedItems]
    );

    const hideCustomizeViewModal = React.useCallback(() => {
        customizeViewHandler(false);
    }, []);

    const uploadCloseClickHandler = React.useCallback(() => {
        toggleUpload(false);
    }, []);

    const handleAddNewClick = () => {
		toggleAdModal(true);
	};

    const handleBulkDeleteClick = () => {
        dispatch(dynamicGridSagaActions.handleBulkRowDelete({ tableId: constants.TABLE_ID }));
        toggleDeleteModal(false);
    };

    const deleteItems = () => {
        toggleDeleteModal(true);
    };

    const isNewUpload = useSelector(isNewUploadEnabled);

    const gridConfig = {
        enableSelection: true,
        enableSorting: true,
        enableMultiSort: true,
        gridActions: hasDeleteAccess,
        customActions: (hasWriteAccess || hasDeleteAccess) && {
          width: 170,
          enabled: true,
          component: ({ row }) => {
            if (!usersList) return null;
            const user = usersList.find(user => user.UserID === row.UserID);
            if (!user) return null;
            
            return (
              user.IsActive ? (
                <Stack gutter="small" align="center" position='center'>
                  <StackItem>
                    <Button handleClick={() => onEditRow(row)}>Edit</Button>
                  </StackItem>
                  <StackItem>
                    <Button type="secondary" handleClick={() => onDeactivateUser(row)}>Deactivate</Button>
                  </StackItem>
                </Stack>
              ) : (
                <Stack gutter="small" align="center" position='center'>
                  <StackItem align='center'>
                    <Button type="secondary" handleClick={() => onActivateUser(row)}>Activate</Button>
                  </StackItem>
                </Stack>
              )
            );
          }
        },
        selectionCallBack: () => { }
      };

    const templateConfig = {
        gridConfig,
        isFilterEnabled: openFilter === undefined ? false : openFilter,
        tableId: constants.TABLE_ID,
        dataProviderOptions: {
            type: 'POST',
            ...REQUESTS.POST.GET_MONGO_GRID_DETAILS_V2,
            pathParams: { tableId: constants.TABLE_ID },
            version: 2
        },
        selectRowKey: 'UserID'
    };

    const columns = getColumns({ onEditRow, onDeactivateUser, onRemoveUserRole, onActivateUser })

    return (
        <>
            <Stack isVertical>
                <StackItem>
                    <GridActionBar>
                        <Stack gutter="medium">
                            <StackItem isGrow>
                                <Breadcrumbs crumbs={props.breadcrumbs} />
                            </StackItem>
                       
                            {/* <StackItem isGrow position='right' align='right'> 
								{hasWriteAccess && <Button handleClick={handleAddNewClick}> Create New User </Button>}
							</StackItem> */}
                            <StackItem isGrow position='right' align='right'> 
                            <Button handleClick={() => setCreateUser(true)}>Create User</Button>
                            </StackItem>

                            {isNewUpload && gridMeta?.UploadTableID && <StackItem>
                                <GridCsvUpload uploadTableId={gridMeta?.UploadTableID} parentTableId={tableId} />
                            </StackItem>}
                            <StackItem>
                                <Icon isActive type="secondary" faAlias="faRecycle" onClick={handleRefreshClick} />
                            </StackItem>


                            <StackItem>
                                <ThetaMenu
                                    type="3Dots"
                                    options={
                                        hasWriteAccess ? constants.MENU_ITEMS : [constants.MENU_ITEMS[0]]
                                    }
                                    handleItemClick={handleMenuItemClick}
                                    isDisabled={getDisabledStatus}
                                />
                            </StackItem>
                        </Stack>
                    </GridActionBar>
                </StackItem>
                <StackItem isStretch isGrow>
                    <Sheet isPage>
                        <Stack isVertical gutter="medium">

                            <StackItem isGrow isStretch>
                                <ThetaGridPaginated key={constants.TABLE_ID} config={templateConfig} />
                            </StackItem>
                        </Stack>
                    </Sheet>
                </StackItem>
            </Stack>

            {createUserInfo && columns && <CreateUser userInfo={createUserInfo} onHide={() => setCreateUser(false)} />}

            {isCustomizeViewModalEnabled && (
                <CustomizeViewModal hideModal={hideCustomizeViewModal} tableId={constants.TABLE_ID} />
            )}
            {isUploadEnabled && (
                <SideCar isActive={isUploadEnabled} handleOnClose={uploadCloseClickHandler}>
                    <UploadData tableId={tableId} />
                </SideCar>
            )}
            {isDeleteModal && (
                <ThetaDeleteModal
                    hideModal={() => {
                        toggleDeleteModal(false);
                    }}
                    modalTitle={'ITEMS'}
                    title={` (${selectedItems?.isSelectAll ? 'All' : selectedItems?.selectedRowKeys?.length
                        }) selected  item(s)`}
                    handleDeleteClick={handleBulkDeleteClick}
                    isLocked={true}
                />
            )}
        </>
    );
};
export default Users;
