// @ts-nocheck
import { Card } from 'antd';
import Button from 'atoms/button/button';
import Heading from 'atoms/heading/heading';
import Icon from 'atoms/icons/icon';
import { ThetaIconPlus, ThetaLogoDark } from 'atoms/icons/navSvg';
import Label from 'atoms/label/label';
import Sheet from 'atoms/sheet/sheet';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import { loginRequest } from 'authConfig';
import { isLoggedOut } from 'models/subscriptions/selector';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import React from 'react';
import { useCallback } from 'react';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SignInButton } from './authentication';
import './login.scss';

export const LoginPage = () => {
	const isLoggedOutUser: any = useSelector(state => isLoggedOut(state));

	return (
		<Sheet isPage>
			<Stack isVertical>
				<StackItem align="center" isStretch isGrow>
					<Card className="login--card">
						<Stack isVertical position="center" gutter="medium" align="center">
							<StackItem>
								<ThetaLogoDark height="200" width="200"></ThetaLogoDark>
							</StackItem>
							<StackItem>
								<Heading type="primary"> Welcome to Skulicity</Heading>
							</StackItem>
							{isLoggedOutUser ? (
								<StackItem>
									<Heading type="secondary">
										{' '}
										Your session has expired please login again to continue.{' '}
									</Heading>
								</StackItem>
							) : (
								<StackItem>
									<Stack isVertical align="center" position="center" gutter="large">
										<StackItem>
											<Stack isVertical position="center" align="center" gutter="small">
												<StackItem>
													<SignInButton />
												</StackItem>
												<StackItem>
													<Label>Use your work email.</Label>
												</StackItem>
											</Stack>
										</StackItem>
									</Stack>
								</StackItem>
							)}
						</Stack>
					</Card>
				</StackItem>
			</Stack>
		</Sheet>
	);
};
