// @ts-nocheck
import Button from 'atoms/button/button';
import { loginRequest } from 'authConfig';
import { updateAppState } from 'models/subscriptions/reducer';
import React from 'react';
import { useCallback } from 'react';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMsalInstance } from 'utils/msalInstance';

// import './userBar.scss';
// import { ReactComponent as UserSVG } from '../../atoms/miscSvg/user.svg';
// import { logOut, getUserBarData, checkLocal, getUserRefresh, getUserGraphData } from '../../API.js';
// import { parseJwt } from '../../Utilities.js';

export const SignOutButton = () => {
	const instance = getMsalInstance();

	const handleLogout = logoutType => {
		if (logoutType === 'popup') {
			instance.logoutPopup({
				postLogoutRedirectUri: '/',
				mainWindowRedirectUri: '/' // redirects the top level app after logout
			});
		}
	};

	return (
		<Button className="ml-auto" handleClick={() => handleLogout('popup')}>
			Sign out
		</Button>
	);
};

export const SignInButton = () => {
	const instance = getMsalInstance();

	const dispatch = useDispatch();
	const handleLogin = async loginType => {
		if (loginType === 'popup') {
			await instance
				.loginPopup(loginRequest)
				.then(a => {
					localStorage.removeItem('urlKey');

					dispatch(
						updateAppState({
							isLoggedOutUser: false,
							isInvalidUser: false
						})
					);
				})
				.catch(e => {
					localStorage.removeItem('urlKey');
					localStorage.clear();
					console.log(e);
				});
		}
	};
	return <Button handleClick={() => handleLogin('popup')} name="Sign In"></Button>;
};
