// @ts-nocheck
import { Layout, Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { NavFooterLogo } from 'atoms/icons/navSvg';
import _ from 'lodash';
import Sidebar from 'molecules/sidebar/sidebar';
import React from 'react';
import { useNavigate } from 'react-router';
import './appSidebar.css';
import AppSidebarItem from './appSidebarItem';
import { Pages } from 'pages';
import { browserHistory } from 'react-router';
import { checkIsActive, getSelectedTab, isRouteChild } from './query';
import { TMenuItem } from './types';
import Icon from 'atoms/icons/icon';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { cancelOngoingRequests } from 'utils/api';
import { useLocation } from 'react-router';
import StackItem from 'molecules/stackItem/stackItem';
import Stack from 'molecules/stack/stack';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Sheet from 'atoms/sheet/sheet';
import Button from 'atoms/button/button';
import Divider from 'atoms/divider/divider';
import { isLoggedOut } from 'models/subscriptions/selector';
import Modal from 'antd/lib/modal/Modal';
import { SignInButton, SignOutButton } from 'molecules/authentication/authentication';
import Label from 'atoms/label/label';
import { useSelector } from 'react-redux';
import { fetchUserData } from 'models/user/sagaActions';
import { getDispatch } from 'reducers';
import { hasNetworkError } from 'models/subscriptions/selector';
import { getWebAppSettings, isAdvertisingReadWrite, isAllOrdersReadWrite, isAppAliasSettingRead, isAppAliasSettingReadWrite, isAvailabilityRead, isAvailabilityReadWrite, isAvailabilityWrite, isCreateOrderRead, isCreateOrderReadWrite, isDemandChangeReadWrite, isDemandGroupHubReadWrite, isDemandReportingReadWrite, isIntelligenceRead, isMasterDataRead, isOrderItemsReadWrite, isOrderKitsReadWrite, isOrderSkusReadWrite, isParametersReadWrite, isPlanogramRestrictionsReacWriteEnabled, isPlanogramRestrictionsReadEnabled, isPlanogramRestrictionsReadWriteEnabled, isPlanogramRestrictionsWriteEnabled } from 'models/user/selector';
import { getMsalInstance } from 'utils/msalInstance';
import { isLoadingEnabled } from 'models/loaders/selectors';
import { AppLoading } from 'appLoading';
import { ThemeProvider } from 'styled';
import theme from 'components/theme';

interface Props extends React.PropsWithChildren<any> {
	isExpanded?: boolean;
}

const getRoutesDir: () => TMenuItem[] = (state, webAppSettings) => [
	{
		id: 1,
		displayName: 'Home',
		Route: '/home',
		imgAlias: 'Theta'
	},
	{
		id: 2,
		displayName: 'Orders',
		Route: '/orders',
		isDisabled: !isAllOrdersReadWrite(state) && !isOrderItemsReadWrite(state) && !isOrderSkusReadWrite(state) && !isOrderKitsReadWrite(state),
		imgAlias: 'Orders',
		children: [
			{
				id: 8,
				displayName: 'All Orders',
				isDisabled: !isAllOrdersReadWrite(state),

				Route: '/orders?selectedTab=all'
				// imgAlias: 'Orders',
			},
			{
				id: 5,
				displayName: 'Order Kits',
				isDisabled: !isOrderKitsReadWrite(state),
				Route: '/orders?selectedTab=kits'
				// imgAlias: 'Orders',
			},
			{
				id: 7,
				displayName: 'Order SKUs',
				isDisabled: !isOrderSkusReadWrite(state),
				Route: '/orders?selectedTab=skus'
				// imgAlias: 'Orders',
			},
			{
				id: 6,
				displayName: 'Order Items',
				isDisabled: !isOrderItemsReadWrite(state),
				Route: '/orders?selectedTab=items'
				// imgAlias: 'Orders',
			}
		]
	},
	{
		id: 3,
		displayName: 'Availability',
		isDisabled: !isAvailabilityReadWrite(state),
		Route: '/availability',
		imgAlias: 'Inventory'
	},
	{
		id: 10,
		displayName: 'Advertising',
		isDisabled: !isAdvertisingReadWrite(state),
		Route: '/advertising?selectedTab=UPCOMING',
		imgAlias: 'Advertising'
	},
	{
		id: 11,

		displayName: 'Parameters',
		isDisabled: !isParametersReadWrite(state),
		Route: '/parameters',
		imgAlias: 'Inventory'
	},
	{
		id: 12,
		displayName: 'Demand Planning',
		Route: '/demand-planning',
		imgAlias: 'DemandPlanningSvg',
		isDisabled: (!webAppSettings?.IsDemandPlanning || !isDemandChangeReadWrite(state) && !isDemandGroupHubReadWrite(state) && !isDemandReportingReadWrite(state)),
		children: [
			{
				id: 15,
				displayName: 'Demand Reporting',
				Route: '/demand-planning?selectedTab=reporting',
				isDisabled: !isDemandReportingReadWrite(state)
				// imgAlias: 'Orders',
			},
			{
				id: 16,
				displayName: 'Demand Change',
				Route: '/demand-planning?selectedTab=matrix',
				isDisabled: !isDemandChangeReadWrite(state)
				// imgAlias: 'Orders',
			},
			{
				id: 17,
				displayName: 'Demand Group Hub',
				Route: '/demand-planning/hub',
				isDisabled: !isDemandGroupHubReadWrite(state)
				// imgAlias: 'Orders',
			}
		]
	},
	{
		id: 18,
		displayName: 'Planogram',
		Route: '/planogram',
		faAlias: 'faCartPlus',
		isDisabled: !isPlanogramRestrictionsReadWriteEnabled(state),
		children: [
			{
				id: 19,
				displayName: 'Planogram Attributes',
				Route: '/planogram/attributes',
				// isDisabled:  !isDemandReportingReadWrite(state)
				// imgAlias: 'Orders',
			},
			{
				id: 20,
				displayName: 'Listing',
				Route: '/planogram/list',
				// isDisabled:  !isDemandChangeReadWrite(state)
				// imgAlias: 'Orders',
			}
		]
	},
	{
		id: 4,
		displayName: 'Master Data',
		Route: '/masterdata',
		imgAlias: 'Data',
		isDisabled: !isMasterDataRead(state)
	},
	{
		id: 9,
		displayName: 'Intelligence',
		Route: '/intelligence',
		imgAlias: 'Intelligence',
		isDisabled: !isIntelligenceRead(state)
	},
	{
		id: 111,
		displayName: 'App Settings',
		Route: '/settings',
		imgAlias: 'Data',
		isDisabled: !isAppAliasSettingReadWrite(state)
	},
];

const AppSidebar: React.FunctionComponent<Props> = (props: Props) => {
	const navigation = useNavigate();
	const isLoadingUserSettings = useSelector(state => isLoadingEnabled(state, 'user-data-loading'))
	const webAppSettings = useSelector(getWebAppSettings);
	const routesDir = useSelector(state => getRoutesDir(state, webAppSettings))
	const routeChild = isRouteChild(routesDir, `${window.location.pathname}${window.location.search}`);
	const isLoggedOutUser: any = useSelector(state => isLoggedOut(state));
	let dispatch = getDispatch();
	const isNetworkError = useSelector(state => hasNetworkError(state));
	const [selectedTab, updateSelectedTab] = React.useState<number>(
		getSelectedTab(routesDir, `${window.location.pathname}${window.location.search}`)
	);
	const msalInstance = getMsalInstance();

	const [isChildRoute, toggleChildRoute] = React.useState<boolean>(routeChild);
	const [isSidebarOpen, toggleSideBar] = React.useState<boolean>(false);
	const [isChildMenuOpen, toggleChildMenu] = React.useState<boolean>(false || routeChild);
	const routes = _.filter(_.flatten(Pages), route => Boolean(route.component));
	const isCreateOrderEnabled = useSelector(isCreateOrderReadWrite);

	const checkUserAvailability = msalInstance.getAllAccounts()

	const isHidden = checkUserAvailability.length <= 0
	const location = useLocation();
	React.useEffect(() => {
		const test = _.filter(routes, route => {
			if (location?.pathname?.match('orders/details') && route?.path?.match('orders/details')) {
				return true;
			}
			else if (location?.pathname?.match('planogram/details') && route?.path?.match('planogram/details')) {
				return true;
			}
			return route.path.toLowerCase() === location.pathname.toLowerCase();
		});

		document.title = test[test?.length - 1]?.title;

	}, [location]);
	//   React.useEffect(() => {
	//     return browserHistory.listen(location => {
	//       updateSelectedTab(
	//         getSelectedTab(routesDir, `${location.pathname}${location.search}`)
	//       );
	//       toggleChildRoute(
	//         isRouteChild(routesDir, `${location.pathname}${location.search}`)
	//       );
	//     });
	//   }, [browserHistory]);

	const handleClick = React.useCallback(
		(e: React.MouseEvent, id: number, path: string) => {
			updateSelectedTab(id);
			// cancelOngoingRequests();
			dispatch(fetchUserData());
			if (selectedTab !== id) {
				toggleChildMenu(false);
			}
			toggleChildRoute(isRouteChild(routesDir, path));
			//   history.push(path);
			//   e.stopPropagation();
		},
		[toggleChildRoute, toggleChildMenu]
	);

	// if (isLoadingUserSettings && !isLoggedOutUser) {
	// 	return null
	// }

	if (isHidden) {
		return null
	}
	return (
		<Layout style={{ minHeight: '100vh' }}>
			{
				isLoadingUserSettings && !isLoggedOutUser ? <AppLoading /> :
					isLoggedOutUser && (
						<div style={{ width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
							<Modal
								centered
								visible
								closable={false}
								maskClosable={false}
								footer={null}
								className="modal-invalid-user"
							>
								<Sheet>
									<SheetBody>
										<Stack gutter="medium" isVertical align="center" position="center">
											<StackItem>
												<Icon imgAlias="ThetaLogoDark" size="large" />
											</StackItem>
											<StackItem position="center">
												{isNetworkError ? (
													<Label>Network Error, please reconnect and sign in to continue.</Label>
												) : (
													<Label>
														{checkUserAvailability.length ? (
															<div style={{ display: "block" }}>
																Hi {checkUserAvailability[0].username}, it looks like you don't have access to Skulicity yet.If you believe this is an error, please email<a href="mailto:help@skulicity.com"> help@skulicity.com</a> for assistance.
															</div>
														) : (
															<>
																Your session has expired. Please log in again to continue.
															</>
														)}
													</Label>
												)}
											</StackItem>
											<StackItem position="center">
												{
													checkUserAvailability.length ?
														<SignOutButton /> : <SignInButton />
												}
											</StackItem>
										</Stack>
									</SheetBody>
								</Sheet>
							</Modal>
						</div>
					)}
			<Sider
				width={220}
				collapsible
				collapsed={isSidebarOpen}
				onCollapse={() => {
					toggleSideBar(!isSidebarOpen);
				}}
			>
				<div className="logo" />
				<Sheet isSidebar>
					<SheetBody isTransparent>
						<Stack isVertical align="center" position="bottom" gutter="small">
							<StackItem align="center" isStretch>
								<SheetBody isTransparent>
									{!isSidebarOpen && isCreateOrderEnabled && (
										<Link
											style={{ color: 'white' }}
											to="/create"
											onClick={() => updateSelectedTab(null)}
										>
											<Button isAppButton>
												<Stack align="space-between" gutter="small" position="center">
													<StackItem isGrow align="center">
														Create Order
													</StackItem>
													<StackItem isStretch>
														<Divider size="small" isVertical></Divider>
													</StackItem>
													<StackItem>
														<Icon
															disabled={false}
															isPadded={false}
															faAlias="faPlus"
															size="tiny"
														/>
													</StackItem>
												</Stack>
											</Button>
										</Link>
									)}
								</SheetBody>
							</StackItem>
							<StackItem isStretch>
								<Menu
									theme="dark"
									defaultSelectedKeys={[selectedTab?.toString()]}
									selectedKeys={[selectedTab?.toString()]}
									mode="inline"
								>
									{_.map(routesDir, (route, index) => {
										return !route?.isDisabled && (route.children && !isSidebarOpen ? (
											<SubMenu
												icon={(route?.imgAlias || route.faAlias) && <Icon isPadded imgAlias={route.imgAlias} faAlias={route.faAlias} />}
												key={route.id}
												title={route.displayName}
											>
												{_.map(route.children, (cRoute, innerIndex) => (
													!cRoute?.isDisabled && <Menu.Item
														onClick={e => handleClick(e, cRoute.id, cRoute.Route)}
														key={cRoute.id}
														icon={
															cRoute.imgAlias && (
																<Icon isPadded imgAlias={cRoute.imgAlias} faAlias={route.faAlias} />
															)
														}
													>
														<Link to={cRoute.Route}>{cRoute.displayName}</Link>
													</Menu.Item>
												))}
											</SubMenu>
										) : (
											<Menu.Item
												onClick={e => handleClick(e, route.id, route.Route)}
												key={route.id}
												icon={(route?.imgAlias || route.faAlias) && <Icon isPadded imgAlias={route.imgAlias} faAlias={route.faAlias} />}
											>
												<Link to={route.Route}>{route.displayName}</Link>
											</Menu.Item>
										));
									})}
								</Menu>
							</StackItem>
							<StackItem isGrow isStretch position="center">
								{!isSidebarOpen ? (
									<Icon imgAlias="NavFooterLogo" size="large" />
								) : (
									<Icon imgAlias="Theta" size="medium" />
								)}
							</StackItem>
						</Stack>
					</SheetBody>
				</Sheet>
			</Sider>
		</Layout>
	);
};

const defaultProps: Props = {
	isExpanded: false
};

AppSidebar.defaultProps = defaultProps;

export default AppSidebar;
