// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import * as constants from './constants';
import { TReducer } from './types';

export const initialState: TReducer = {
	userName: '',
	userEmail: '',
	grpId: '',
	settings: null,
	operations: [],
	currentWorkspace: null,
	allTenentList: []
};

const slice = createSlice({
	initialState,
	name: `user`,
	reducers: {
		updateUserInfo(state, { payload: { userName, userEmail, grpId, settings, webAppSettings, 
			operations, allTenentList} }) {
			return {
				...state,
				userName: userName || state.userName,
				userEmail: userEmail ||  state.userEmail,
				grpId:  grpId || state.grpId ,
				settings:  settings || state.settings ,
				operations: operations || state.operations ,
				webAppSettings: webAppSettings || state.webAppSettings,
				allTenentList: allTenentList || state.allTenentList
			};
		},
		updateGrpId(state, { payload: { grpId } }) {
			return {
				...state,
				grpId: grpId 
			};
		},
		updateAllTenentList(state, { payload: { allTenentList } }) {
			return {
				...state,
				allTenentList: allTenentList 
			};
		},
		updateWorkspace(state, { payload: { workspaceProps } }) {
			return {
				...state,
				currentWorkspace: workspaceProps
			};
		}
	}
});

export default slice;

export const { updateUserInfo, updateWorkspace, updateAllTenentList, updateGrpId} = slice.actions;
