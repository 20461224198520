// @ts-nocheck
import './app.scss';

import { LicenseManager } from 'ag-grid-enterprise';
import { AppLoading } from 'appLoading';
import Header from 'atoms/header/header';
import theme from 'components/theme';
import _ from 'lodash';
import { LoginPage } from 'molecules/authentication/login';
import { ReleaseVersion } from 'molecules/releaseVersion/releaseVersion';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import UserBar from 'molecules/userBar/userBar';
import AppSidebar from 'organisms/appSidebar';
import CreateOrderStatus from 'organisms/CreateOrderStatus';
import Notification from 'organisms/notifications/notification';
import { BreadCrumbsPages, Pages } from 'pages';
import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { render } from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';
import registerServiceWorker from 'registerServiceWorker';
import { ThemeProvider } from 'styled';
import { getMsalInstance } from 'utils/msalInstance';
import Socket from 'utils/sockets';
import Workspace from 'workspace/workspace';

import { NetworkValidator } from './molecules/networkValidator/networkValidator';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from './msal-react';
import Home from './pages/home/home';
import { createThetaStore, getState } from './reducers';
import rootSaga from './sagas';
import { getAclOperations, hasAclOperations } from 'models/user/selector';
import { isLoadingEnabled } from 'models/loaders/selectors';
import { isThetaApp } from 'utils/utilities';
import { isLoggedOut } from 'models/subscriptions/selector';
import SheetBody from 'atoms/sheet/sheetBody/sheetBody';
import Sheet from 'atoms/sheet/sheet';
import Heading from 'atoms/heading/heading';
import { NavFooterLogo, ThetaLogoDark } from 'atoms/icons/navSvg';
import Icon from 'atoms/icons/icon';

try {
	if (window.location.origin.match(/dev-staging|staging|localhost|-dev/)) {
		LicenseManager.setLicenseKey(
			'For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-1_October_2022_[v2]_MTY2NDU3ODgwMDAwMA==5f7c547bfa346b114d2e599f9df6045b'
		);
	} else {
		LicenseManager.setLicenseKey(
			'CompanyName=Theta Retail,LicensedApplication=Theta Retails,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-031754,SupportServicesEnd=3_October_2023_[v2]_MTY5NjI4NzYwMDAwMA==05391522b76a1eca5e981d373263213f'
		);
	}

	const sagaMiddleware = createSagaMiddleware();
	const store = createThetaStore(sagaMiddleware);

	sagaMiddleware.run(rootSaga);

	if (!window.location.origin.match(/dev-staging|localhost|-dev/)) {
		console.log = () => { };
	}
	//
	const Root = () => {
		const routes = _.filter(_.flatten(Pages), route => Boolean(route.component));
		const isAclOperationsValid = useSelector(hasAclOperations);
		const isLoadingUserSettings = useSelector(state => isLoadingEnabled(state, 'user-data-loading'))
		return (
			<ThemeProvider theme={theme}>
				{/* <Router> */}

				<Notification />
				<AppLoading>
					{isAclOperationsValid && !isLoadingUserSettings && <ReleaseVersion>
						<Routes>
							{_.compact(_.map(routes, (route, index) => {
								const breadCrumbRoutes = _.flatten(BreadCrumbsPages);

								const breadcrumbs = breadCrumbRoutes.filter(({ path, index }) => {
									if (
										route.path.split('/')[1] === path.split('/')[1] &&
										path.split('/').length === 2
									) {
										return true;
									}
									return route.path === path;
								});
								return route.component && (route.isEnabled ? route.isEnabled(getState()) : true) ? (
									<Route
										key={index}
										exact
										path={route.path}
										element={
											<Suspense fallback={<span></span>}>
												<Workspace
													componentProps={_.pick(route, [
														'path',
														'componentId',
														'title',
														'name',
														'headerName'
													])}
												>
													{React.createElement(route.component, { breadcrumbs })}
												</Workspace>
											</Suspense>
										}
									></Route>
								) : (
									<Route key={index} exact path="/v1" element={<Home />}></Route>
								);
							}))}
							<Route path="*" element={<Navigate to="/home" />} />
						</Routes>
					</ReleaseVersion>
					}
				</AppLoading>
			</ThemeProvider>
		);
	};
	const msalInstance = getMsalInstance();



	const RootRedirectComponent = () => {
		let [counter, setCounter] = useState(5)
		useEffect(() => {
			setTimeout(() => {
				if (counter === 1) {
					window.location.href = 'http://app.skulicity.com'
					setCounter(1);
				} else {
					setCounter(counter - 1);
				}
			}, 1000)
		}, [counter])

		return <Sheet isPage>
			<SheetBody>
				<Stack isVertical align='center' gutter="medium">
					<StackItem>
						<Stack gutter="medium" align='center'>

							<StackItem isStretch>
								<Icon imgAlias="NavTheta" size="large" />
							</StackItem>
							<StackItem position='center'>
								<Icon faAlias="faArrowRight" size="large" />
							</StackItem>
							<StackItem isStretch>
								<Icon imgAlias="ThetaLogoDark" size="extraLarge" />
							</StackItem>
						</Stack>
					</StackItem>
					<StackItem>
						<Heading type="primary">
							Theta Retail is now Skulicity
						</Heading>
					</StackItem>
					<StackItem>
						Redirecting in {counter} secs
					</StackItem>
				</Stack>
			</SheetBody></Sheet>

	};

	const RootComponent = () => {
		let loginHash;
		if (window.opener) {
			loginHash = window.location.hash || JSON.parse(localStorage.getItem('urlKey'))?.hash;
		}


		return (
			<MsalProvider instance={msalInstance}>
				{!loginHash ? (
					<BrowserRouter>
						<Provider store={store}>
							<Socket>
								<Stack>
									<StackItem>
										<AppSidebar></AppSidebar>
									</StackItem>
									<StackItem isStretch isGrow>
										<Stack isVertical>
											<StackItem>
												<NetworkValidator />
											</StackItem>
											<StackItem>
												<CreateOrderStatus />
											</StackItem>
											<UnauthenticatedTemplate>
												<StackItem isStretch isGrow>
													<Stack isVertical>
														<StackItem isStretch>
															{/* <Header type="secondary"> */}
															<UserBar></UserBar>
															{/* </Header> */}
														</StackItem>
														<StackItem isGrow>
															<LoginPage></LoginPage>
														</StackItem>
													</Stack>
												</StackItem>
											</UnauthenticatedTemplate>
											<AuthenticatedTemplate>
												<StackItem isStretch isGrow>
													<Stack isVertical>
														<StackItem isStretch>
															<Header type="secondary" isAppHeader>
																<UserBar isAuthenticated></UserBar>
															</Header>
														</StackItem>
														<StackItem isStretch isGrow>
															<Root />
														</StackItem>
													</Stack>
												</StackItem>
											</AuthenticatedTemplate>
										</Stack>
									</StackItem>
								</Stack>
							</Socket>
						</Provider>
					</BrowserRouter>
				) : (
					<div>Redirecting</div>
				)}
			</MsalProvider>
		);
	};

	// this hack saves application from login logout loop not to be removed
	if (window.location.hash !== '') {
	}

	const root = document.getElementById('root') as HTMLElement;
	render(isThetaApp() ? <RootRedirectComponent /> : <RootComponent />, root);
	registerServiceWorker();
} catch (e) {
	console.error('App Level error', e);
}
