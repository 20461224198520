import AxiosCall from './axiosCall';
import _ from 'lodash';
import { devProxyAPI, parseJwt, tokenExpiryListener } from './utilities';
import Axios from 'axios';
import { getAclBaseURL, getApiBaseURL, isStagingApp } from './helpers';
import { getDispatch, getState } from 'reducers';
import { updateAppState } from 'models/subscriptions/reducer';
import { loginRequest, msalConfig } from 'authConfig';
import { getCachePolicyInstance, getMsalInstance } from './msalInstance';
import { updateAllTenentList, updateUserInfo } from 'models/user/reducer';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { getUserGrpId } from 'models/user/selector';
import { hasNetworkError } from 'models/subscriptions/selector';
import { getUserEmail } from 'models/user/selector';
import { REQUESTS } from './requests';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
require('axios-debug-log');

let currentToken = null;
require('axios-debug-log');
export let tokenSources = [];
const overrideEmail = localStorage.getItem('overrideEmail');

let tokenInterval;
const triggerTokenTimeout = accessToken => {
	// clearInterval(tokenInterval);
	// tokenInterval = setInterval(() => {
	// 	let currentTime = Date.now();
	// 	let expiration = jwt_decode(accessToken).exp * 1000;
	// 	if (currentTime > expiration - 10000) {
	// 		const msalInstance = getMsalInstance();
	// 		msalInstance.acquireTokenSilent(msalConfig);
	// 		clearInterval(tokenInterval);
	// 	}
	// }, 1000);
};

const config = {
	apiBaseURL: getApiBaseURL(),
	aclBaseURL: getAclBaseURL()
};

function generateParams() {
	let AUTH_PARAMS = new URLSearchParams();
	return AUTH_PARAMS;
}

export function checkLocal() {
	let env;
	try {
		env = require('./env.json');
	} catch (error) {
		// console.error(error); // We don't care if this errors, no need to spam the console in prod
		env = false;
	}
	return env;
}


export function getAPIData(url, obj) {
	return apiCall('GET', url, obj);
}
export function getAPIDataV2(service, url, obj) {
	return apiCallV2('GET', service, url, obj);
}

export function getAPIDataV3({ service, url, body, queryParams, pathParams }) {
	return newApiCallV2({ type: 'GET', service, url, body, queryParams, pathParams });
}

export function patchAPIDataV3({ service, url, body, queryParams, pathParams }) {
	return newApiCallV2({ type: 'PATCH', service, url, body, queryParams, pathParams });
}

export function deleteAPIDataV3({ service, url, body, queryParams, pathParams }) {
	return newApiCallV2({ type: 'DELETE', service, url, body, queryParams, pathParams });
}

export function postAPIDataV3({ service, url, body, queryParams, pathParams }) {
	return newApiCallV2({ type: 'POST', service, url, body, queryParams, pathParams });
}

export function putAPIDataV3({ service, url, body, queryParams, pathParams }) {
	return newApiCallV2({ type: 'PUT', service, url, body, queryParams, pathParams });
}

export async function cancelOngoingRequests() {
	await _.forEach(tokenSources, async source => {
		await source.cancel('CANCELLED');
	});
	// tokenSources = [];
}

const getAppInfo = () => {
	let appId;
	if (window.location.origin.match(/dev-staging|localhost/)) {
		appId = 'theta-dev-staging';
	} else if (window.location.origin.match(/staging|localhost/)) {
		appId = 'theta-prod-staging';
	} else {
		appId = 'theta-prod';
	}
	return {
		appId
	};
};

function apiCallRouter(type, call, requestObj) {
	let obj = requestObj instanceof FormData ? requestObj : { ...requestObj, ...getAppInfo() };
	let source = Axios.CancelToken.source();
	tokenSources.push(source);
	switch (type) {
		case 'GET': {
			return call.get('', { params: obj, cancelToken: source.token });
		}
		case 'POST': {
			return call.post('', obj, { cancelToken: source.token });
		}
		case 'PATCH': {
			return call.patch('', obj, { cancelToken: source.token });
		}
		case 'PUT': {
			return call.put('', obj, { cancelToken: source.token });
		}
		case 'DELETE': {
			return call.delete('', obj);
		}
		default: {
			return call.get('');
		}
	}
}

function appendPathParamsToUrl(url, pathParams) {
	if (!pathParams) return url;
	Object.keys(pathParams).forEach((key) => {
		url = url.replace(`{{${key}}}`, pathParams[key]);
	})
	return url;
}

function appendQueryParamsToUrl(url, queryParams) {
	if (!queryParams) return url;
	Object.keys(queryParams).forEach((key, index) => {
		if (index === 0) {
			url += `?${key}=${queryParams[key]}`
		} else {
			url += `&${key}=${queryParams[key]}`
		}
	})
	return url;
}

async function newApiCallV2({ type, service, url, body, queryParams, pathParams }) {
	let axiosData = {};
	const isNetworkError = hasNetworkError(getState());

	const dispatch = getDispatch();
	try {
		const {
			token,
			userData: { userEmail, grpId }
		} = await getToken();

		let userJson = {
			email: overrideEmail || userEmail,
			groupId: grpId
		};
		let base64EncodedUserJson = window.btoa(JSON.stringify(userJson));
		let baseURL = config.apiBaseURL;

		// let baseURL = window.location.origin.match(/localhost/) ? 'http://localhost:8050' : config.apiBaseURL;

		url = appendPathParamsToUrl(url, _.omitBy(pathParams, _.isNil))
		url = appendQueryParamsToUrl(url, _.omitBy(queryParams, _.isNil))

		if (isStagingApp()) {
			baseURL = `${baseURL}/api/${service}/staging/${base64EncodedUserJson}/${url}`;
		} else {
			baseURL = `${baseURL}/api/${service}/${base64EncodedUserJson}/${url}`;
		}

		if (service === 'acl') {
			// baseURL = window.location.origin.match(/localhost/) ? `http://localhost:8060/api/${base64EncodedUserJson}/${url}` : `${config.aclBaseURL}/${base64EncodedUserJson}/${url}`;
			baseURL = `${config.aclBaseURL}/${base64EncodedUserJson}/${url}`;

		}

		axiosData.INSTANCE = new AxiosCall({
			baseURL,
			timeout: 300000
		});
		if (!token) {
			throw new Error('Invalid Token');
		}
		axiosData.INSTANCE.setAuthorization(token);
	} catch (e) {
		if (e?.response?.status === 401) {
			dispatch(
				updateAppState({
					isLoggedOutUser: true
				})
			);
		}
		return Promise.reject({});
	}
	return apiCallRouter(type, axiosData.INSTANCE, body).catch(error => {
		if (error?.response?.status === 401) {
			dispatch(
				updateAppState({
					isLoggedOutUser: true
				})
			);
			return Promise.reject({});
		}
		return Promise.reject(error.response || error);
	});
}


export async function getAllTenent(email, token) {
	const dispatch = getDispatch();
	let axiosData = {};
	try {
		let baseURL;

		if (isStagingApp()) {
			baseURL = `${config.apiBaseURL}/api/staging/tenant/list`;
		} else {
			baseURL = `${config.apiBaseURL}/api/tenant/list`;
		}

		axiosData.INSTANCE = new AxiosCall({
			baseURL,
			timeout: 60000
		});
		if (!token) {
			throw new Error('Invalid Token');
		}

		axiosData.INSTANCE.setAuthorization(token);
	} catch (e) {
		dispatch(
			updateAppState({
				isLoggedOutUser: true
			})
		);
		return
	}
	return apiCallRouter("POST", axiosData.INSTANCE, { email }).catch(error => {
		if (error?.response?.status === 401) {
			dispatch(
				updateAppState({
					isLoggedOutUser: true
				})
			);
			return Promise.reject({});
		}
		return Promise.reject(error.response || error);
	});
}

export async function selectTenent(email,partnerId, token) {
	const dispatch = getDispatch();
	let axiosData = {};
	try {
		let baseURL;

		if (isStagingApp()) {
			baseURL = `${config.apiBaseURL}/api/staging/tenant/selectcompany`;
		} else {
			baseURL = `${config.apiBaseURL}/api/tenant/selectcompany`;
		}

		axiosData.INSTANCE = new AxiosCall({
			baseURL,
			timeout: 60000
		});
		if (!token) {
			throw new Error('Invalid Token');
		}

		axiosData.INSTANCE.setAuthorization(token);

	} catch (e) {
		dispatch(
			updateAppState({
				isLoggedOutUser: true
			})
		);
		return
	}
	return apiCallRouter("POST", axiosData.INSTANCE, { email, partnerId }).catch(error => {
		if (error?.response?.status === 401) {
			dispatch(
				updateAppState({
					isLoggedOutUser: true
				})
			);
			return Promise.reject({});
		}
		return Promise.reject(error.response || error);
	});
}

export async function getUserTenent(email, token) {
	const dispatch = getDispatch();
	let axiosData = {};
	try {
		let baseURL;

		if (isStagingApp()) {
			baseURL = `${config.apiBaseURL}/api/staging/tenant/Selectedcompany`;
		} else {
			baseURL = `${config.apiBaseURL}/api/tenant/Selectedcompany`;
		}

		axiosData.INSTANCE = new AxiosCall({
			baseURL,
			timeout: 60000
		});
		if (!token) {
			throw new Error('Invalid Token');
		}

		axiosData.INSTANCE.setAuthorization(token);

	} catch (e) {
		dispatch(
			updateAppState({
				isLoggedOutUser: true
			})
		);
		return
	}
	return apiCallRouter("POST", axiosData.INSTANCE, { email }).catch(error => {
		if (error?.response?.status === 401) {
			dispatch(
				updateAppState({
					isLoggedOutUser: true
				})
			);
			return Promise.reject({});
		}
		return Promise.reject(error.response || error);
	});
}


const getToken = async () => {
    const msalInstance = getMsalInstance();
    const email = getUserEmail(getState());
    const account = msalInstance.getAccountByUsername(email);
    let userData;

    try {
        // Check if the current token is expired or about to expire (less than 5 minutes)
        let expiration = currentToken ? (jwt_decode(currentToken)?.exp ?? 0) * 1000 - Date.now() : 0;
        
        // If the token is expired or doesn't exist, attempt to acquire a new one
        if (expiration < 5 * 60 * 1000 || !currentToken) {
            let data = await msalInstance.acquireTokenSilent({
                scopes: loginRequest.scopes,
                loginHint: email,
                cacheLookupPolicy: getCachePolicyInstance().AccessTokenAndRefreshToken,
                account: account
            });

            // Set the current token
            currentToken = 'Bearer ' + data?.accessToken;
            userData = await fetchAndUpdateUserInfo(data);
        } else {
            // Token is still valid; use existing token for user data
            userData = {
                userEmail: email,
                grpId: getUserGrpId(getState())
            };
        }

        return { token: currentToken, userData };
    } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
            try {
                // If silent token acquisition fails, prompt the user for re-authentication
                let data = await msalInstance.acquireTokenRedirect({
                    scopes: loginRequest.scopes,
                    loginHint: email,
                    account: account
                });

                currentToken = 'Bearer ' + data?.accessToken;
                userData = await fetchAndUpdateUserInfo(data);
                return { token: currentToken, userData };
            } catch (redirectError) {
                console.error('Error during redirect token acquisition:', redirectError);
            }
        } else {
            console.error('Error acquiring token:', e);
        }

        // Dispatch logout state if an error occurs
        const dispatch = getDispatch();
        dispatch(updateAppState({ isLoggedOutUser: true }));
    }
};


async function apiCallAcl(type, service, url, obj) {
	let axiosData = {};
	const isNetworkError = hasNetworkError(getState());

	const msalInstance = getMsalInstance();

	const dispatch = getDispatch();
	try {
		const {
			token,
			userData: { userEmail, grpId }
		} = await getToken();

		let userJson = {
			email: overrideEmail || userEmail,
			groupId: grpId
		};
		let base64EncodedUserJson = window.btoa(JSON.stringify(userJson));
		let baseURL = config.aclBaseURL;
		// let baseURL = window.location.origin.match(/localhost/) ? 'http://localhost:8050' : config.aclBaseURL;

		if (isStagingApp()) {
			baseURL = `${baseURL}/${base64EncodedUserJson}/${service}/${url}`;
		} else {
			baseURL = `${baseURL}/${base64EncodedUserJson}/${service}/${url}`;
		}

		axiosData.INSTANCE = new AxiosCall({
			baseURL,
			timeout: 60000
		});
		if (!token) {
			throw new Error('Invalid Token');
		}
		axiosData.INSTANCE.setAuthorization(token);
	} catch (e) {
		dispatch(
			updateAppState({
				isLoggedOutUser: true
			})
		);
		return Promise.reject({});
	}
	return apiCallRouter(type, axiosData.INSTANCE, obj).catch(error => {
		if (error?.response?.status === 401) {
			dispatch(
				updateAppState({
					isLoggedOutUser: true
				})
			);
			return Promise.reject({});
		}
		return Promise.reject(error.response || error);
	});
}


async function apiCallV2(type, service, url, obj) {
	let axiosData = {};
	const isNetworkError = hasNetworkError(getState());

	const msalInstance = getMsalInstance();

	const dispatch = getDispatch();
	try {
		const {
			token,
			userData: { userEmail, grpId }
		} = await getToken();

		let userJson = {
			email: overrideEmail || userEmail,
			groupId: grpId
		};
		let base64EncodedUserJson = window.btoa(JSON.stringify(userJson));
		// let baseURL = '';
		let baseURL = config.apiBaseURL;
		// let baseURL = window.location.origin.match(/localhost/) ? 'http://localhost:8050' : config.apiBaseURL;

		if (isStagingApp()) {
			baseURL = `${baseURL}/api/${service}/staging/${base64EncodedUserJson}/${url}`;
		} else {
			baseURL = `${baseURL}/api/${service}/${base64EncodedUserJson}/${url}`;
		}

		axiosData.INSTANCE = new AxiosCall({
			baseURL,
			timeout: 60000
		});
		if (!token) {
			throw new Error('Invalid Token');
		}
		axiosData.INSTANCE.setAuthorization(token);
	} catch (e) {
		dispatch(
			updateAppState({
				isLoggedOutUser: true
			})
		);
		return Promise.reject({});
	}
	return apiCallRouter(type, axiosData.INSTANCE, obj).catch(error => {
		if (error?.response?.status === 401) {
			dispatch(
				updateAppState({
					isLoggedOutUser: true
				})
			);
			return Promise.reject({});
		}
		return Promise.reject(error.response || error);
	});
}

async function apiCall(type, url, obj) {
	const dispatch = getDispatch();
	let axiosData = {};
	const isNetworkError = hasNetworkError(getState());

	try {
		const {
			token,
			userData: { userEmail, grpId }
		} = await getToken();
		let userJson = {
			email: overrideEmail || userEmail,
			groupId: grpId
		};
		let base64EncodedUserJson = window.btoa(JSON.stringify(userJson));
		let baseURL;

		if (isStagingApp()) {
			baseURL = `${config.apiBaseURL}/api/staging/${url}`;
		} else {
			baseURL = `${config.apiBaseURL}/api/${url}`;
		}

		axiosData.INSTANCE = new AxiosCall({
			baseURL,
			timeout: 60000
		});
		if (!token) {
			throw new Error('Invalid Token');
		}

		axiosData.INSTANCE.setAuthorization(token);
	} catch (e) {
		dispatch(
			updateAppState({
				isLoggedOutUser: true
			})
		);
		return Promise.reject({});
	}
	return apiCallRouter(type, axiosData.INSTANCE, obj).catch(error => {
		if (error?.response?.status === 401) {
			dispatch(
				updateAppState({
					isLoggedOutUser: true
				})
			);
			return Promise.reject({});
		}
		return Promise.reject(error.response || error);
	});
}

export function getUserData() {
	return AxiosCall.post('/.auth/me', {
		headers: {
			'access-control-allow-origin': window.location.origin
		}
	});
}

export function getUserGraphData(auth) {
	let inst = Axios.create({
		baseURL: 'https://graph.microsoft.com/v1.0',
		timeout: 600000
		// headers: { 'Authorization': 'Bearer ' + auth }
	});
	// inst.defaults.headers.common['Authorization'] = 'Bearer ' + auth;
	return inst.get('/me');
}


async function fetchAndUpdateUserInfo(data) {
	const dispatch = getDispatch();

	let idToken = data.idToken;
	let accessToken = data.accessToken;
	let grpId = getUserGrpId(getState())
	let allTenentList = []
	if (!idToken) {
		throw new Error('token expired');
	}
	let auth_obj = parseJwt(accessToken);
	const userEmail = overrideEmail || auth_obj?.email || auth_obj?.unique_name;
	if (!grpId) {
		const response = await getUserTenent(userEmail, data.accessToken)
		const userDetails = response?.data?.data
		if (userDetails) {
			grpId = userDetails.PartnerID
		} else {
			const response = await getAllTenent(userEmail, data.accessToken)
			allTenentList = response?.data?.data?.selectedparentPartners ?? []
			// dispatch(
			// 	updateAllTenentList({allTenentList})
			// );
			if (allTenentList && allTenentList.length > 0) {
				const selectedTenent = await selectTenent(userEmail, allTenentList[0].PartnerID, data.accessToken)
				grpId = selectedTenent?.data?.data?.PartnerId
			}
		}
	}
	// let id_obj = parseJwt(idToken);
	// grpId = id_obj?.groups?.[0];
	sessionStorage.setItem('grpid', grpId);
	sessionStorage.setItem('userEmail', userEmail);
	sessionStorage.setItem('userName', auth_obj.name);
	let userInfo = { grpId, userEmail, accessToken };
	let userJson = {
		email: userEmail,
		groupId: grpId
	};
	let base64EncodedUserJson = window.btoa(JSON.stringify(userJson));

	if (window.clarity) {
		window.clarity("identify", base64EncodedUserJson, "session-" + userEmail)
		window.clarity("set", "email", userEmail)
	}

	dispatch(
		updateUserInfo({
			userName: overrideEmail || auth_obj.name,
			userEmail,
			grpId,
			allTenentList
		})
	);
	return userInfo;
}

export function getUserBarData() {
	return AxiosCall.post('/.auth/me', null, {
		headers: {
			'access-control-allow-origin': window.location.origin
		}
	}).catch(error => ({ ...error, isError: true }));
}

export function getUserSettingsData() {
	return getAPIDataV3(REQUESTS.GET.GET_USER_CONFIG)
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
}


export function getAccessControlOperations() {
	return getAPIDataV3(REQUESTS.GET.GET_USER_CONFIG)
		.then(response => ({ response: response?.data }))
		.catch(error => ({ error, isError: true }));
}


export function getUserRefresh() {
	return AxiosCall.get('/.auth/refresh');
}

export function logOut(refresh) {
	localStorage.clear();
	sessionStorage.clear();
	document.cookie.split(';').forEach(function (c) {
		document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
	});
	if (refresh) {
		window.location.reload();
	}
	window.location.href = '/.auth/logout?post_logout_redirect_uri=/index.html';
}

export async function silentLogin() {
	if (!!window.chrome) {
		let domainHint = window.location.host.split('.')[0]; //Domain of your organization users (e.g. me@contoso.com)

		//Remove existing iframe (if exists), to minimize history/back button entries
		let existingFrame = document.getElementById('authIFrame');
		if (existingFrame) {
			existingFrame.remove();
		}

		//Inject iFrame that will call endpoint to refresh token/cookie

		let iframe = document.createElement('iframe');
		iframe.id = 'authIFrame';
		iframe.style = 'width: 0; height: 0; border: 0; border: none; position: absolute; visibility: hidden;';
		iframe.src = `/.auth/login/aad?prompt=none`;
		document.body.appendChild(iframe);
		await new Promise((resolve, reject) => setTimeout(resolve, 2000));
	} else {
	}
}

export function relogin() {
	window.location.replace('/.auth/login/aad?post_login_redirect_url=/?restoreData=true');
}

export function postAPIData(url, obj) {
	return apiCall('POST', url, obj);
}

export function getAPIDataAcl(url, service, obj) {
	return apiCallAcl('GET', url, service, obj);
}


export function putAPIData(url, obj) {
	return apiCall('PUT', url, obj);
}

export function putAPIDataV2(service, url, obj) {
	return apiCallV2('PUT', service, url, obj);
}

export function postAPIDataV2(service, url, obj, options) {
	return apiCallV2('POST', service, url, obj, options);
}

export function patchAPIDataV2(service, url, obj) {
	return apiCallV2('PATCH', service, url, obj);
}

export function deleteAPIData(url, obj) {
	return apiCall('DELETE', url, obj);
}

export function deleteAPIDataV2(service, url, obj) {
	return apiCallV2('DELETE', service, url, obj);
}

const getRefreshQueryString = () => {
	const d = new Date();
	return `refresh=${d.getTime().toString()}`;
};

export const checkPollingStatus = async statusId => {
	if (statusId) {
		try {
			const response = await getAPIDataV3({
				...REQUESTS.GET.GET_PROCESS_STATUS,
				pathParams: { statusId },
				queryParams: { refresh: new Date().getTime().toString() },
			});

			// if (response?.data.ResultSets?.Table1[0].CurrentStatus === 'Complete') {
			// 	return { success: true, isError: false }
			// }
			return { status: response?.data.ResultSets?.Table1[0].CurrentStatus }; //TODO: PLease check this
		} catch (error) {
			console.error('No StatusID received.', error);
			return { success: false, isError: true };
		}
	} else {
		return { success: false, isError: true };
	}
};

export const checkPollingStatusV2 = async statusId => {
	if (statusId) {
		try {
			const response = await getAPIDataV3({
				service: REQUESTS.GET.GET_PROCESS_STATUS.service,
				url: REQUESTS.GET.GET_PROCESS_STATUS.url,
				pathParams: { statusId },
				queryParams: { refresh: new Date().getTime().toString() }
			});
			return { status: response?.data.data.CurrentStatus };

			// if (response?.data.data[0].CurrentStatus === 'Complete') {
			// 	return { success: true, isError: false }
			// }
			// return setTimeout(() => checkPollingStatus(statusId), 15000); //TODO: PLease check this
		} catch (error) {
			console.error('No StatusID received.', error);
			return { success: false, isError: true };
		}
	} else {
		return { success: false, isError: true };
	}
};

export async function getPowerBIDBID() {
	// This url and all logic app urls need to be secret or at least the sig needs to be secret
	// const DB_SELECT_URL = "https://prod-46.eastus.logic.azure.com:443/workflows/66f051ed6699423fbda05bfd05e1ae14/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=A0ntiU_BKa7iuoE80WgewMWGsUzPN9K_mYIxFlU-7Yo";
	// var grpId = sessionStorage.getItem('grpid');

	if (checkLocal()) {
		return postAPIData('dbselect', { headers: { grpId: '8d0a5a83-72cf-4806-8a28-381b0540b013' } });
		// return Axios.post(DB_SELECT_URL, null, {headers: {"grpId": "999dev"}});
	} else {
		AxiosCall.post('/.auth/me')
			.then(response => {
				let idToken = response?.data[0].id_token;
				let id_obj = parseJwt(idToken);
				let grpId = id_obj.groups[0];
				sessionStorage.setItem('grpid', grpId);

				return postAPIData('dbselect', { headers: { grpId: grpId } });
				// return Axios.post(DB_SELECT_URL, null, {headers: {"grpId": grpId}});
			})
			.catch(error => {
				console.error('ERROR >>> ', error);
				return Promise.resolve(error);
			});
	}
}
